<template>
  {{ message }}
  <div v-if="user">{{ user.profile.email }}</div>
</template>

<script>
import { defineComponent } from "vue";
import { datadogLogs as log } from "@datadog/browser-logs";
import PubNub from "pubnub";
import EnsureCloud from "../helpers/ensure-cloud";

export default defineComponent({
  components: {},
  inject: ["auth"],
  data() {
    return {
      message: "Hello World",
      user: null,
    };
  },
  watch: {
    auth: {
      handler() {
        this.checkAuth();
      },
      deep: true,
    },
  },
  created() {
    if (this.auth.client) {
      this.checkAuth();
    }
  },
  methods: {
    async checkAuth() {
      EnsureCloud();
      try {
        await this.auth.getTokenSilently();
        this.user = await Cloud.whoami()
          .headers({
            Authorization: `Bearer ${this.$store.state.token}`,
          })
          .tolerate((err) => {
            log.logger.error(err);
          });
      } catch (err) {
        log.logger.error(err);
      }
      if (!this.user) {
        log.logger.info("User is not authenticated");
        window.parent.postMessage(
          {
            type: "authenticated",
            value: false,
          },
          "*"
        );
      } else {
        log.logger.info("Logged In Manually", this.user);
        window.parent.postMessage(
          {
            type: "authenticated",
            value: true,
          },
          "*"
        );
        window.analytics?.track("Extension Embedded", {
          userId: this.user?.id,
        });

        const pubnub = new PubNub({
          subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
          publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
          ssl: true,
          userId: this.user?.id,
        });
        pubnub.setToken(this.user.pubnub.token);

        pubnub.addListener({
          message: (m) => {
            console.log(m);

            if (m.message.type === "replayDrafted") {
              window.parent.postMessage(m.message, "*");

              window.analytics?.track("Extension Inject Replay", {
                userId: this.user?.id,
              });
            }
          },
        });

        pubnub.subscribe({
          channels: [this.user.pubnub.channels.userActivity],
        });
      }
    },
  },
});
</script>
